import React,{ useEffect, useMemo } from 'react';
import ExamModal from './ExamModal';

import { setOnePaperLoginCookieByServiceRoot, setOnePaperLoginCookieByOrganizationId, } from 'helper/onePaper';
import { useSetState } from 'utils/hooks/useSetState';
import { useParams } from 'react-router-dom';
import { useAlert } from 'utils/hooks/useAlert';
import { UiActionBox, UiflexBox } from 'styles';
import {
  Icon,
  Table,
  // Select,
  HomeTitle,
  BsModal,
  HomeOnlineExamTableV2,
  Button,
  Loading,
} from 'components';
import { getGroupInfoById } from 'services/api/home/class';
import { getOnePaper as getOnePaperApi, } from 'services/api/groups/papers';
import { useUser } from 'store/user';
import { useClass } from 'store/class';
import { createOrganizationGroupExam } from 'services/api/home/onlineExam';
import {
  Tab,
  TextField as MatTextField
} from '@material-ui/core';
import {
  UiHomeOnlineExam,
  UiMatTabs,
  UiExamIcons,
} from './HomeOnlineExam.style';


/**
 * 線上測驗頁面for Teacher
 */

const schema = {
  year: {
    name: '學年度',
    defaultValue: '',
  },
  eduSubjectName: {
    name: '學制科目',
    defaultValue: '',
  },
  name: {
    name: '試卷名稱',
    defaultValue: '',
  },
  // groupUsageCount: {
  //   name: '已派卷次數',
  //   defaultValue: ''
  // },
};

export const HomeOnlineExam = ({ token, isTeacher }) => {
  const [{
    tabIndex,
    subjectKey,
    searchValue,
    nowPage,
    rowsPage,
    classUsers,
    isPromptModal,
    // subjectPlaceholder,
    placeholder,
    examPaper,
    // eduSubjectMap,
    examPaperTotal,
    isOpenModal,
    paperId,
    examName,
    examPeriod,
    examTeachers,
    startAt,
    endAt,
    isLoading,
    eduSubjectName,
  }, setState] = useSetState({
    tabIndex: 0,
    subjectKey: '',
    searchValue: '',
    nowPage: 0,
    rowsPage: 10,
    classUsers: [],
    isPromptModal: false,
    // subjectPlaceholder:'全部科目',
    placeholder: '搜尋試卷名稱',
    examPaper: [],
    // eduSubjectMap : [],
    examPaperTotal: 0,
    isOpenModal: false,
    paperId: '',
    examName: '',
    examPeriod: null,
    examTeachers: [],
    startAt: null,
    endAt: null,
    isLoading: false,
    eduSubjectName: null,
  });

  const { classId, organizationId } = useParams();
  const { setAlert } = useAlert();
  const [{ profile }] = useUser();
  const [{ myClasses: { dataInfo: { userType, year } } }] = useClass();

  const getTabIndexHandler = (e, tabIndex) => {
    setState({
      tabIndex
    });
  };

  /******* 取得 OnePaper 資料 *******/
  const fetchOnePaperInfo = async () => {
    setState({ isLoading: true });
    if (!year) return;
    const { isSuccess, data, } = await getOnePaperApi(classId, year);

    if (isSuccess) {
      const { papers, total } = data;
      // 過濾選項之程式
      // eduSubjectMap -> ex. [{ name: 國中數學, code: 'JMA' },
      //                       { name: 國中英語, code: 'JEN' },
      //                       { name: 國小生活, code: 'ELI' }]
      // const nextEduSubject = [{
      //   name:'全部科目',
      //   value:''
      // }].concat(eduSubjectMap.map(eduSubject=> ({
      //   name: eduSubject.name,
      //   value: eduSubject.code
      // })));

      setState({
        examPaper: papers,
        examPaperTotal: total,
        // eduSubjectMap: nextEduSubject, // 過濾之選項
      });
    }
    setState({ isLoading: false });
  };

  const examPaperList = useMemo(() => {
    // 將測驗列表依序做「過濾、搜尋、分頁」
    return examPaper.map(paper => ({
      ...paper,
      year: paper.year,
      // code: paper.attributes.education + paper.attributes.subject, // 下行選項欄位之範例，ex. JMA, JEN, ...
      code: '', // 選項欄位（科目代碼），TODO: API 尚未增加此屬性
    })).filter(paper => subjectKey === '' || subjectKey === paper.code)
      .filter(paper => searchValue === '' || paper.name.includes(searchValue))
      .slice(nowPage * rowsPage, nowPage * rowsPage + rowsPage);
  }, [examPaper, nowPage, rowsPage, subjectKey, searchValue]);

  // 換頁
  const changePage_Rows = params => {
    // newPage 第幾頁
    // newRowsPage 一頁幾筆
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  // 沒有學生的彈窗控制
  const onPrompt = () => {
    setState({
      isPromptModal: !isPromptModal
    });
  };

  // 科目 select
  // const onSubjectSelectHandler = value => {
  //   setState({
  //     subjectKey: value,
  //   });
  // };

  const closeModal = () => {
    setState({ isOpenModal: false });
  };

  // 操作
  const ActionComponents = ({ params }) => {
    const { eduSubjectName, name, uid: paperId, } = params;
    const isCantCreate = classUsers?.length > 0;
    const onViewSession = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/paper/preview/${paperId}`;
      window.open(url);
    };

    const onCreateExam = () => {
      setState({
        eduSubjectName,
        paperId,
        examName: name,
        isOpenModal: true,
      });
    };

    return (
      <UiExamIcons>
        <div
          tabIndex={0}>
          <Icon.Svg
            name="Visibility"
            onClick={onViewSession}
          />
        </div>

        <div
          tabIndex={0}>
          <Icon.Svg
            name="Plane"
            onClick={isCantCreate ? onCreateExam : onPrompt}
            color="#ec7963"
          />
        </div>
      </UiExamIcons>
    );
  };

  // input change
  const onSearchChange = event => {
    setState({
      searchValue: event.target.value,
    });
  };


  const fetchClasses = async () => {
    const { isSuccess, data } = await getGroupInfoById(classId);
    if (isSuccess) {
      setState({
        classUsers: data.users,
      });
    } else {
      setAlert('取得學生列表失敗', 'error');
      setState({
        classUsers: [],
      });
    }
  };

  const onModalChange = (value, key) => {
    setState({
      [key]: value
    });
  };

  const createExam = async (type) => {
    const payload = {
      eduSubjectName,
      paperId,
      organizationId,
      groupId: classId,
      period: parseInt(examPeriod),
      name: examName,
      markers: examTeachers,
    };

    if (type === 'reserve') {
      payload.startAt = startAt;
      payload.endAt = endAt;
    }
    if (!examName) setAlert('請輸入測驗名稱', 'error');
    if (!examPeriod) setAlert('請輸入測驗時間', 'error');
    if (!examTeachers.length) setAlert('請勾選批改老師', 'error');
    if (!examName || !examPeriod || !examTeachers.length) return;
    if (type === 'reserve' && (!startAt || !endAt)) return;
    setState({
      isLoading: true
    });
    const { isSuccess } = await createOrganizationGroupExam(payload);
    if (isSuccess) {
      setAlert('派卷成功', 'success');
      setState({
        paperId: '',
        examPeriod: null,
        examName: '',
        examTeachers: [],
        startAt: '',
        endAt: '',
        isOpenModal: false
      });
    } else {
      setAlert('派卷失敗', 'error');
    }
    setState({
      isLoading: false
    });
  };

  const onClickPrepareExamHandler = () => {
    setOnePaperLoginCookieByServiceRoot();
    setOnePaperLoginCookieByOrganizationId(organizationId);
    // 跳轉 onePaper
    window.open(process.env.REACT_APP_ONEPAPER_DOMAIN);
  };

  // filter
  // useEffect(() => {
  //   const educationKey = classes.dataInfo?.educationName;
  //   if(educationKey) {
  //     if(!subjectMap[educationKey]) {
  //       emptySubjectOption();
  //     } else {
  //       const subjectOption = subjectMap[educationKey].map(item=>({
  //         ...item,
  //         value: item.code,
  //       }));
  //       const nextSubject = subjectOption.length > 0 ? [
  //         {
  //           code:'all',
  //           value:'all',
  //           name:'全部科目'
  //         },
  //         ...subjectOption
  //       ] : [];
  //       setState({
  //         subjectOption : nextSubject,
  //         subjectKey: '',
  //         subjectPlaceholder: '全部科目'
  //       });
  //     }
  //   }
  //   setState({
  //     searchValue: '',
  //   });
  // }, [classes.dataInfo?.educationName, subjectMap]);

  useEffect(() => {
    fetchClasses();
    fetchOnePaperInfo();
    // fetchOrganizationGroupPaperOptions();
  }, [year]);

  useEffect(() => {
    setState({
      tabIndex: isTeacher ? 0 : 1
    });
  }, [isTeacher]);

  return (
    <UiHomeOnlineExam>
      <HomeTitle title={'線上測驗'} />
      {isTeacher &&
        <UiMatTabs
          value={tabIndex}
          onChange={getTabIndexHandler}
        >
          <Tab label="試卷列表" />
          <Tab label="測驗列表" />
        </UiMatTabs>}
      {tabIndex === 0 &&
        <UiActionBox>
          <UiflexBox>
            {/* <Select
              label={subjectPlaceholder}
              options={eduSubjectMap}
              submitHandler={onSubjectSelectHandler}
            /> */}
            <MatTextField
              variant='outlined'
              value={searchValue}
              placeholder={placeholder}
              onChange={onSearchChange}
            />
          </UiflexBox>
          {userType === 'teacher' &&
            <Button onClick={onClickPrepareExamHandler}>
              前往出卷
            </Button>
          }
        </UiActionBox>
      }
      {
        isLoading
          ? <Loading />
          : tabIndex === 0
            /* 試卷列表 */
            ? <Table
                data={examPaperList}
                schema={schema}
                changePage_Rows={changePage_Rows}
                totalPage={examPaperTotal}
                ActionComponents={ActionComponents}
                nowPage={nowPage}
              />
            /* 測驗列表 */
            : <HomeOnlineExamTableV2 />
      }

      <BsModal
        open={isPromptModal}
        title="警告"
        isFull={true}
        cancelDisplay={false}
        onOk={onPrompt}
        onCancel={onPrompt}
      >
        此班級內沒有學生
      </BsModal>

      {
        isOpenModal &&
          <ExamModal
            isOpen={isOpenModal}
            onClick={createExam}
            onClose={closeModal}
            isLoading={isLoading}
            onChange={onModalChange}
            startAt={startAt}
            endAt={endAt}
            examName={examName}
            examPeriod={examPeriod}
            examTeachers={examTeachers}
          />
      }
    </UiHomeOnlineExam>
  );
};
