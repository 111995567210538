import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import {
  Table,
  Icon,
  Button,
  Loading,
} from 'components';
import { stringify } from 'query-string';
import { getMarkToken } from 'services/api/home/exam';
import { getGroupExamResults } from 'services/api/home/onlineExam';
import { useUser } from 'store/user/index';
import { useAlert } from 'utils/hooks/useAlert';
import { usePagination } from 'utils/hooks/usePagination';
import {
  UiActions,
  UiHomeExamResults,
  UiHomeExamGoBack,
  UiHomeExamTitleWrapper,
  UiHomeExamTable,
  UiEmptyBox,
  UiEmptyImage,
  UiEmptyText,
  UiTitle,
} from './HomeExamResults.style';
import { format } from 'date-fns';
import emptyExam from 'assets/images/emptyExam.png';


/**
 * 作答結果
 */

 const EXAMNOTFINISHED = 'examNotFinished';
 const ANSWERED = 'answered';
 const MISSED = 'missed';
 const TOBECORRECTED = 'toBeCorrected';

 const EXAM_STATUS = {
   [EXAMNOTFINISHED]: '尚未作答',
   [ANSWERED]: '作答完成',
   [MISSED]: '缺考',
   [TOBECORRECTED]: '待批改'
 };

 const schema = {
  attendeeNumber: {
    name: '座號',
    defaultValue: '-',
  },
  userNickname:{
    name:'學生姓名',
    defaultValue: '',
  },
  statusText:{
    name:'狀態',
    defaultValue:'',
  },
  // submitTime:{
  //   name:'交卷時間',
  //   defaultValue:'',
  // },
  // answerPeriodText:{
  //   name:'作答時間',
  //   defaultValue:'',
  // },
  // scoreText:{
  //   name:'分數',
  //   defaultValue:'-',
  // }
};

export const HomeExamResults = () => {
  const history = useHistory();
  const { examName, includingWriting } = history.location.state;
  const { classId, examId } = useParams();
  const { setAlert } = useAlert();
  const [{ profile }] = useUser();
  const { nickname } = profile;
  const [isLoading, setIsLoading] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  const fetchMarkToken = async () => {
    let token = null;
    const url = window.location.href;
    const payload = {
      examId: examId,
      teacherName: nickname,
      returnUrl: url,
    };
    const response = await getMarkToken(payload);
    const { status } = response;
    status === 'success'
      ? token = response.content.token
      : setAlert(response.content, 'error');
    return token;
  };

  /* click 非選批改 按鈕 */
  const clickMark = async () => {
    setIsLoading(true);
    const token = await fetchMarkToken();
    if (token) {
      const params = {
        token,
      };
      const querystring = stringify(params);
      const markUrl = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/exam/mark/${examId}?${querystring}`;
      window.open(markUrl, '_blank');
    }
    setIsLoading(false);
  };

  // 操作
  const ActionComponents = ({ params }) => {
    const { status, userId, examId } = params;
    const goExamResults = () => {
      const url = `${process.env.REACT_APP_ONEEXAM_DOMAIN}/user/report/${examId}/${userId}`;
      window.open(url);
    };

    return (
      <Button onClick={goExamResults} disabled={status !== ANSWERED}>
        作答結果
      </Button>
    );
  };

  const { isFetching, data , total } = usePagination(
    () => getGroupExamResults(
      classId,
      examId
    ),
    {
      resKeyId:'id',
      deps: [classId],
      page: 0,
      limit: 100,
      formatResult: data => data.map( item => {
        const { status, submittedAt, answerPeriod } = item;
        return {
          ...item,
          scoreText: item.score.toString(),
          statusText: <div key={item.examId} style={{ color: status === MISSED ? '#F44336' : '#505565'}}>
              {EXAM_STATUS[status]}
          </div>,
          submitTime: submittedAt ? format(submittedAt,'yyyy-MM-dd HH:mm') : '-',
          answerPeriodText: answerPeriod ? `${answerPeriod} 分` : '-',
        };
      })
    });

  return (
    <UiHomeExamResults>
      <UiHomeExamGoBack onClick={goBack}>
        <Icon.Svg name="ChevronRight" />
        <span>返回上一頁</span>
      </UiHomeExamGoBack>
      <UiHomeExamTitleWrapper>
        <UiTitle>{examName}</UiTitle>
        <UiActions>
          { 
            includingWriting &&
              <Button
                buttonColor='new'
                loading={isLoading}
                onClick={clickMark}
              >
                非選批改
              </Button>
          }
        </UiActions>
      </UiHomeExamTitleWrapper>
      <UiHomeExamTable>
        {
          isFetching
            ? <Loading />
            : data.length === 0
                ? <UiEmptyBox>
                    <UiEmptyImage path={emptyExam} />
                    <UiEmptyText>該測驗目前尚無學生進行作答</UiEmptyText>
                  </UiEmptyBox>
                : <Table
                    data={data}
                    schema={schema}
                    totalPage={total}
                    ActionComponents={ActionComponents}
                    isNeedPagination={false}
                  />
        }
      </UiHomeExamTable>
    </UiHomeExamResults>
  );
};

HomeExamResults.propTypes = {

};
