import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox as MuiCheckbox,
  FormControl as MuiFormControl,
} from '@material-ui/core';
import { UiSearchWrapper, UiTeachersContainer, UiMuiFormControl, UiMuiFormControlLabel } from './Teachers.style';
import { IconInput, Loading, Select, } from 'components';
import { useTeacher } from 'store/teacher';

const SELECT_LABEL = '搜尋目標';
const SELECT_OPTIONS = [
  { name: '暱稱', value: 'nickname' },
  { name: '帳號ID', value: 'id' },
];

export const Teachers = ({ examTeachers, isLoading, setIsLoading, onChange }) => {
  const [{ allTeachers }, { getAllTeachers }] = useTeacher();
  const [selectValue, setSelectValue] = useState(null);
  const [teachersOri, setTeachersOri] = useState([]);
  const [teachers, setTeachers] = useState([]);

  /**
   * 按下 enter for IconInput
   * @param {string} value input string
   */
  const handleSearch = (value) => {
    let newTeachers = null;
    if (selectValue === SELECT_OPTIONS[0].value) {
      newTeachers = teachersOri.filter(({ nickname }) => nickname.includes(value));
    }
    else if (selectValue === SELECT_OPTIONS[1].value) {
      newTeachers = teachersOri.filter(({ id }) => id.includes(value));
    }
    else {
      newTeachers = teachersOri.filter(({ nickname, id }) => nickname.includes(value) || id.includes(value));
    }
    setTeachers(newTeachers);
  };

  /**
   * change select 搜尋目標
   * @param {string} value select 選到的 value
   */
  const changeSelect = (value) => {
    setSelectValue(value);
  };

  /* 格式化 API 資料 */
  const formatAllTeachers = () => {
    const isEdit = examTeachers.length > 0;
    let newTeachers = allTeachers.data.map(({ id, nickname }) => ({ checked: false, id, nickname }));
    if (isEdit) {
      examTeachers.forEach(teacher => {
        const index = newTeachers.findIndex(({ id }) => id === teacher);
        newTeachers[index].checked = true;
      });
    } 
    setTeachers(newTeachers);
    setTeachersOri(newTeachers);
  };

  /**
   * 勾選 checkbox
   * @param {object} event checkbox event
   */
  const changeCheckbox = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    onChange(name, checked);
  };

  /* 打 API，取得所有老師 */
  const fetchAllTeachers = async () => {
    setIsLoading(true);
    await getAllTeachers();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllTeachers();
  }, []);

  useEffect(() => {
    if (!allTeachers.total) return;
    formatAllTeachers();
  }, [allTeachers.total, examTeachers]);

  return (
    isLoading
      ? <Loading />
      : <>
          <UiSearchWrapper>
            <Select
              label={SELECT_LABEL}
              onChangeHandler={changeSelect}
              options={SELECT_OPTIONS}
            />
            <IconInput
              isFull
              onClick={handleSearch}
              onKeyPress={handleSearch}
              placeholder='搜尋'
            />
          </UiSearchWrapper>
          <UiTeachersContainer>
            <UiMuiFormControl fullWidth>
              <MuiFormControl>
                {teachers.map(({ checked, id, nickname }) =>
                  <UiMuiFormControlLabel key={id}
                    control={<MuiCheckbox onChange={changeCheckbox} checked={checked} color="primary" name={id} />}
                    label={`${nickname} (ID: ${id})`}
                  />
                )}
              </MuiFormControl>
            </UiMuiFormControl>
          </UiTeachersContainer>
        </>
  );
};

Teachers.propTypes = {
  examTeachers: PropTypes.array,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  setIsLoading: PropTypes.func,
};