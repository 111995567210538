import styled from 'styled-components';
import {
	TableBody,
	TableCell,
	TableRow,
	Tabs as MatTabs,
} from '@material-ui/core';

export const UiHomeOnlineExam = styled.a`
	display: block;
	margin-top: 1rem;
`;

export const UiTitle = styled.div`
	font-size: 20px;
	color: #242c3f;
	margin-bottom: 1.5rem;
`;

export const UiMatTabs = styled(MatTabs)`
	display: block;
	margin: 1rem 0;

	.MuiTab-textColorInherit.Mui-selected {
		color: #ec7963;
	}

	> div > span {
		background-color: #ec7963;
	}
`;

export const UiExamIcons = styled.div`
	display: flex;

	> div {
		font-size: 2rem;
		cursor: pointer;

		> svg {
			width: auto;
			height: auto;
		}
	}
`;
