import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  IconButton as MatIconButton,
  Avatar as MatAvatar
} from '@material-ui/core';

import { useUser } from 'store/user';
import { ClickablePopoverMenu } from 'components';
import { UiAvatarMenu, UiButtonWrapper } from './AvatarMenu.style';
import { COMPANY_TITLE } from 'constants/index';


/**
 * Header 用頭像組件
 */

const isCompany = process.env.REACT_APP_TITLE === COMPANY_TITLE;

export const AvatarMenu = () => {
  const history = useHistory();
  const [{ profile, myAllOrganizations }, { userLogout }] = useUser();
  const isHome = useRouteMatch({ path: '/home' }) ? true : false;

  const logoutHandler = () => {
    userLogout();
  };

  const goToHome = () => {
    history.push('/home');
  };

  const goToMemberCenter = () => {
    history.push('/home/membercenter/profile');
  };

  const goToAdminPage = () => {
    // eslint-disable-next-line max-len
    isCompany
      ? window.location.href = `https://onelink-oms${process.env.REACT_APP_ENV === 'release' ? '' : '-' + process.env.REACT_APP_ENV}.oneclass.com.tw/home`
      : window.location.href = process.env.REACT_APP_URL_BACKSTAGE;
  };

  const popoverMenuList = () => {
    const list = [
      { label: profile.nickname ? profile.nickname : profile.lastName + profile.firstName },
      { label: '班級列表', func: goToHome, iconName: 'GroupList', isSvg: true, },
      { label: '會員中心', func: goToMemberCenter, iconName: 'Member', isSvg: true, },
      { label: '登出', func: logoutHandler, iconName: 'Logout', isSvg: true, },
      { label: `版本 v${process.env.REACT_APP_VERSION || '1.0.0'}` }
    ];
    if (isHome && Object.keys(myAllOrganizations.ownOrganizationsDataMap).length > 0) {
      list.splice(2, 0,
        { label: '後台管理', func: goToAdminPage, iconName: 'Oms', isSvg: true, }
      );
    }
    return list;
  };




  return (
    <UiAvatarMenu>
      {
        profile && profile.id && (
          <ClickablePopoverMenu menuList={popoverMenuList()}>
            <UiButtonWrapper>
              <MatIconButton color="inherit">
                <MatAvatar src={profile.thumbnailUrl || ''} alt={profile.nickname} aria-label="使用者選單" />
              </MatIconButton>
            </UiButtonWrapper>
          </ClickablePopoverMenu>
        )
      }
    </UiAvatarMenu>
  );
};

AvatarMenu.propTypes = {
  userId: PropTypes.string
};


