import React from 'react';
import { UiFooter, UiRow, UiLink, UiContent } from './Footer.style';
import { useHistory } from 'react-router-dom';
import { COMPANY_TITLE } from 'constants/index';

/**
 * 在這邊描述這個組件
 */

const isCompany = process.env.REACT_APP_TITLE === COMPANY_TITLE;

export const Footer = () => {
  const history = useHistory();
  const goToRule = () => {
    history.push('/rule');
  };
  return (
    <>
      {
        isCompany
          ? <UiFooter>
              <UiRow>
                <UiLink onClick={() => { goToRule(); }}>
                  OneLink 政策暨服務條款
                </UiLink>
              </UiRow>
              <UiRow>
                <UiContent />
                <UiContent>
                  Made with Copyright © NANI BOOK ENTERPRISE CO.,LTD.All Rights Reserved
                </UiContent>
                <UiContent>v{process.env.REACT_APP_VERSION}</UiContent>
              </UiRow>
            </UiFooter>
          : <UiFooter>
              <UiContent>v{process.env.REACT_APP_VERSION}</UiContent>
            </UiFooter>
      }
    </>

  );
};

Footer.propTypes = {

};


