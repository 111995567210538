import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MatTextField } from '@material-ui/core';
import { UiFormBox, UiTabBox, UiTabItem } from './BasicData.style';
import { Icon, DateTimePicker } from 'components';

const CREATE_TYPE = {
  NOW:'now',
  RESERVE:'reserve'
};

export const BasicData = ({
  createExamType,
  disabledTab,
  endAt,
  examName,
  examPeriod,
  startAt,
  onChange,
  onChangeCreateType,
}) => {
  return (
    <>
      {!disabledTab &&
        <UiTabBox>
          <UiTabItem
            className={createExamType === CREATE_TYPE.NOW ? 'active' : ''}
            onClick={()=> onChangeCreateType(CREATE_TYPE.NOW)}
          >
            <Icon.Svg name="Rocket" color={createExamType === CREATE_TYPE.NOW ? '#fff':'#8B90A0'}/>
            <span>立即派卷</span>
          </UiTabItem>
          <UiTabItem
            className={createExamType === CREATE_TYPE.RESERVE ? 'active' : ''}
            onClick={()=> onChangeCreateType(CREATE_TYPE.RESERVE)}
          >
            <Icon.Svg name="Calendar"color={createExamType === CREATE_TYPE.RESERVE ? '#fff':'#8B90A0'}/>
            <span>預約派卷</span>
          </UiTabItem>
        </UiTabBox>}

      <UiFormBox position={createExamType === CREATE_TYPE.NOW ? '24%' : '72%'}>
        <MatTextField
          label="測驗名稱*"
          variant="outlined"
          style={{width: '70%', margin: '10px 0'}}
          value={examName || ''}
          onChange={e => onChange(e.target.value,'examName')}
        />
        <MatTextField
          label="作答時間(分鐘)*"
          variant="outlined"
          type="number"
          style={{width: '70%', margin: '10px 0'}}
          value={examPeriod || null}
          onChange={e => onChange(e.target.value,'examPeriod')}
        />
        {createExamType === CREATE_TYPE.RESERVE &&
        <DateTimePicker
          label="開始時間*"
          style={{width: '70%', margin: '10px 0'}}
          value={startAt || null}
          onChange={value => onChange(value,'startAt')}
        />}
        {
          (examPeriod && startAt && createExamType === CREATE_TYPE.RESERVE) &&
          <DateTimePicker
            label="結束時間*"
            style={{width: '70%', margin: '10px 0'}}
            value={endAt || null}
            onChange={value => onChange(value,'endAt')}
          />
        }
      </UiFormBox>
    </>
  );
};

BasicData.defaultProps = {
  disabledTab: false,
};

BasicData.propTypes = {
  createExamType: PropTypes.string,
  disabledTab: PropTypes.bool,
  endAt: PropTypes.number,
  examName: PropTypes.string,
  examPeriod: PropTypes.number,
  startAt: PropTypes.number,
  onChange: PropTypes.func,
  onChangeCreateType: PropTypes.func,
};
