import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BasicData from './BasicData';
import Teachers from './Teachers';
import { BsModal } from 'components';
import { useSetState } from 'utils/hooks/useSetState';

const CREATE_TYPE = {
  NOW:'now',
  RESERVE:'reserve'
};

const BASIC_DATA_TEXT = {
  cancelText: '取消',
  okText: '下一步',
  title: '我要派卷',
};

const TEACHERS_TEXT = {
  cancelText: '上一步',
  okText: '派卷',
  title: '選擇批改老師',
};

export const ExamModal = ({
  title = '我要派卷',
  isOpen,
  onClick,
  onClose,
  isLoading,
  defaultType,
  onChange,
  examPeriod,
  startAt,
  endAt,
  examName,
  examTeachers,
  disabledTab
}) => {
  const [{
    createExamType,
  }, setState] = useSetState({
    createExamType: defaultType || CREATE_TYPE.NOW,
  });
  const [isLoadingTeachers, setIsLoadingTeachers] = useState(false);
  const [modal, setModal] = useState({
    cancelText: BASIC_DATA_TEXT.cancelText,
    okText: BASIC_DATA_TEXT.okText,
    onCancel: null,
    onOk: null,
    step: 1,
    title: title || BASIC_DATA_TEXT.title,
  });

  const onChangeCreateType = type => {
    setState({
      createExamType:type
    });
  };

  /**
   * 選擇批改老師的勾選 checkbox 方法
   * @param {string} name checkbox name
   * @param {bool} checked checkbox isChecked
   */
  const changeCheckboxTeachers = (name, checked) => {
    let newExamTeachers = [];
    if (checked && !newExamTeachers.includes(name)) {
      newExamTeachers = [ ...examTeachers ];
      newExamTeachers.push(name);
    } else {
      newExamTeachers = examTeachers.filter(item => item !== name);
    }
    onChange(newExamTeachers, 'examTeachers');
  };

  /* 派卷 */
  const clickOkTeachers = () => {
    onClick(createExamType, examTeachers);
  };

  /* 選擇批改老師彈窗 上一步 */
  const clickCancelTeachers = () => {
    setModal({
      cancelText: BASIC_DATA_TEXT.cancelText,
      okText: BASIC_DATA_TEXT.okText,
      onCancel: onClose,
      onOk: clickOkBasicData,
      step: 1,
      title:title || BASIC_DATA_TEXT.title,
    });
  };

  /* 基本資料彈窗 下一步 */
  const clickOkBasicData = () => {
    setModal({
      cancelText: TEACHERS_TEXT.cancelText,
      okText: TEACHERS_TEXT.okText,
      onCancel: clickCancelTeachers,
      onOk: clickOkTeachers,
      step: 2,
      title: TEACHERS_TEXT.title,
    });
  };

  useEffect(() => {
    setModal({
      cancelText: BASIC_DATA_TEXT.cancelText,
      okText: BASIC_DATA_TEXT.okText,
      onOk: clickOkBasicData,
      onCancel: onClose,
      step: 1,
      title: title || BASIC_DATA_TEXT.title,
    });
    // return () => setModal({ step: 1 });
  }, []);

  useEffect(() => {
    setModal(prev => ({
      ...prev,
      onCancel: clickCancelTeachers,
      onOk: clickOkTeachers,
    }));
  }, [examTeachers]);

  useEffect(() => {
    setModal(prev => ({
      ...prev,
      onCancel: onClose,
      onOk: clickOkBasicData,
    }));
  }, [examName, examPeriod, startAt, endAt]);

  return (
    <BsModal
      isLoading={isLoading || isLoadingTeachers}
      cancelText={modal.cancelText}
      okText={modal.okText}
      onCancel={modal.onCancel}
      onClose={onClose}
      onOk={modal.onOk}
      open={isOpen}
      title={modal.title}
    >
      {
        modal.step === 1 &&
          <BasicData
            createExamType={createExamType}
            disabledTab={disabledTab}
            endAt={endAt}
            examName={examName}
            examPeriod={examPeriod}
            onChange={onChange}
            onChangeCreateType={onChangeCreateType}
            startAt={startAt}
          />
      }

      {
        modal.step === 2 &&
          <Teachers
            examTeachers={examTeachers}
            isLoading={isLoadingTeachers}
            onChange={changeCheckboxTeachers}
            setIsLoading={setIsLoadingTeachers}
          />
      }

    </BsModal>
  );
};

ExamModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  isLoading : PropTypes.bool,
  defaultType: PropTypes.string,
  onChange: PropTypes.func,
  examPeriod: PropTypes.number,
  startAt: PropTypes.number,
  endAt: PropTypes.number,
  examName: PropTypes.string,
  examTeachers: PropTypes.array,
  disabledTab: PropTypes.bool
};
